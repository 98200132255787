import { useApp } from 'components/AppContext'

export default function useCollectionConfig(id) {
  const { collectionConfig } = useApp()

  return [id ? collectionConfig[id] : collectionConfig, false]
}

export function CollectionConfigProvider(props) {
  console.warn('[Deprecated] CollectionConfigProvider is a noop')
  return props.children
}
