import useCollectionConfig from 'hooks/useCollectionConfig'
import { useMemo, useState } from 'react'
import titleCase from 'title-case'
import { pathOr } from 'ramda'

export function makeTranslate(config, namespace = null, argLength = null) {
  return function translate(collectionId, ...rest) {
    if (!collectionId || (argLength !== null && rest.length !== argLength)) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn('Invalid use of collectionTranslations', {
          collectionId,
          namespace,
          argLength,
          rest
        })
        return 'INVALID_COLLECTION_TRANSLATION'
      }
      return null
    }

    const translationPath = rest
      .slice(0, argLength === null ? Infinity : argLength)
      // TODO: should r ever be undefined?
      .map((r) => r?.replace(/(\d+)\./, ''))

    const path = [
      collectionId,
      'translations',
      ...(namespace ? [namespace] : []),
      ...translationPath
    ]

    const val = pathOr(null, path, config)
    if (!val) {
      const fallback =
        argLength === 0
          ? titleCase(collectionId)
          : titleCase(path[path.length - 1])
      return fallback
    }
    return val
  }
}

export function makeTranslators(config) {
  return {
    field: makeTranslate(config, 'fields', 1),
    option: makeTranslate(config, 'options', 2),
    value: makeTranslate(config, 'values', 2),
    collectionName: makeTranslate(config, 'name', 0),
    name: makeTranslate(config, 'name', 0)
  }
}

export default function useCollectionTranslations(_configOverride) {
  const [config] = useCollectionConfig()
  const [configOverride] = useState(() => _configOverride)

  return useMemo(() => makeTranslators(configOverride || config), [
    config,
    configOverride
  ])
}
